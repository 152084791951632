<template>
  <click-to-edit
    v-model="scale.plan"
    :disabled="disabled"
    style="width: 100%"
    type="textarea"
    @input="$emit('change', scale.plan)"
  ></click-to-edit>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit.vue";
import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";

export default {
  name: "quality-card-plan-view",
  mixins: [dynamicScaleComponent],
  components: {ClickToEdit},
  props: {
    disabled:{type: Boolean, default: false}
  },
  data() {
    return {
      scale:{
        plan: null,
      },
    }
  },
}
</script>